<template lang="pug">
	section#institucional
		.wrapper
			div.titulo
				h3 {{institucional.nome}}
				p {{institucional.titulo}}
			div.beneficios
				p(v-html='institucional.conteudo')
			div.valorAnual
				h4 Anuidade:
				strong
					|De 
					span R$ {{ associacao.valor.completo }}
					|  por
				p.valor
					span R$ 
					| {{ associacao.valor_desconto.real }}
					span.centavos ,{{ associacao.valor_desconto.centavos }}
				p.desconto 5% de desconto no boleto
				button(@click="$carrinho.adicionar(associacao.produto_id)", :disabled="$carrinho.inProdutos(associacao.produto_id)").obter 
					| {{ getTextButtonAddCarrinho(associacao) }}
</template>

<script>
export default {
	props: {
		institucional: Object,
		associacao: Object
	},
	methods: {
		getTextButtonAddCarrinho(associacao) {
			if (this.$auth.usuarioHasAssociacao(associacao.slug)) {
				if (this.$auth.isUsuarioAssociado(associacao.slug)) {
					return 'Aumente sua Anuidade'
				} else {
					return 'Renove sua Anuidade'
				}
			} else {
				if(this.$carrinho.inProdutos(associacao.produto_id))
					return 'Adicionado';
			}
			return 'Obter Anuidade ANCP';
		}
	}
};
</script>

<style lang="stylus" scoped src="./Institucional.styl"></style>
