<template lang="pug">
	section#categorias
		.wrapper
			h3 Categorias
			ul
				li(v-for="associacao, index in associacoes", v-if="associacao.slug != 'institucional'")
					strong 
						|De 
						span R$ {{ associacao.valor.completo }}
						|  por
					p.valor
						| {{ associacao.valor_desconto.real }}
						span 
							|,{{ associacao.valor_desconto.centavos }}
					p.desconto 5% de desconto no boleto
					h4 {{ associacao.nome }}
					p.descricao {{ associacao.descricao }}
					button(@click="$carrinho.adicionar(associacao.produto_id)", :disabled="$carrinho.inProdutos(associacao.produto_id)").obter 
						| {{ getTextButtonAddCarrinho(associacao) }}
			// p.aviso
				|"* Estudante de curso de especialização como pós-graduação, mestrado, doutorado, pós-doutorado, entre outros, não se encaixa nessa categoria."

</template>

<script>
export default {
	props: {
		associacoes: Array
	},
	methods: {
		getTextButtonAddCarrinho(associacao) {
			if (this.$auth.usuarioHasAssociacao(associacao.slug)) {
				if (this.$auth.isUsuarioAssociado(associacao.slug)) {
					return 'Aumente sua Anuidade'
				} else {
					return 'Renove sua Anuidade'
				}
			} else {
				if(this.$carrinho.inProdutos(associacao.produto_id))
					return 'Adicionado';
			}
			return 'Obter Anuidade ANCP';
		}
	}
}
</script>

<style lang="stylus" scoped src="./Categorias.styl"></style>