<template lang="pug">
	section#sejaAssociado
		img(:src="require('@images/backgrounds/bg-associado.png')")
		.wrapper
			div
				h2 {{sejaAssociado.nome}}
				p(v-html='sejaAssociado.conteudo')
			button(@click="$parent.makeScroll('#categorias')")
				|{{textButton1}}
				br
				|{{textButton2}}
</template>

<script>
export default {
	props: {
		sejaAssociado: Object,
	},
	data(){
		return {
			textButton1:'Quero ser',
			textButton2:'um associado'
		};
	},
	created() {
		this.getTextButtonGoToAssociacao();
	},
	methods:{
		getTextButtonGoToAssociacao(){
			if(this.$auth.usuarioHasAssociacao()){
				if(this.$auth.isUsuarioAssociado()){
					this.textButton1 = 'Aumente sua';
					this.textButton2 = 'anuidade';
				}else{
					this.textButton1 = 'Renove sua';
					this.textButton2 = 'anuidade';
				}
			}else{
				this.textButton1 = 'Quero ser';
				this.textButton2 = 'um associado';
			}
		}
	}
};
</script>

<style lang="stylus" scoped src="./SejaAssociado.styl"></style>
