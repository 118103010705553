<template lang="pug">
	section#beneficios
		.wrapper
			h3 Benefícios
			ul
				li(v-for="item,index in beneficios")
					div.img
						img(:src="require('@images/icones/buscar.png')")
					div.beneficio
						h4 {{item.titulo}}
						p(v-html="item.conteudo")
			a(@click.prevent="$parent.makeScroll('#categorias')").custo Quanto custa?
</template>

<script>
export default {
	props: {
		beneficios: Array
	}
};
</script>

<style lang="stylus" scoped src="./Beneficios.styl"></style>
