<template lang="pug">
	article#associado
		SejaAssociado(:sejaAssociado="conteudo.apresentacao", v-if="conteudo")
		Beneficios(:beneficios="conteudo.beneficios", v-if="conteudo")
		Categorias(:associacoes="conteudo.associacoes", v-if="conteudo")
		Institucional(:institucional="conteudo.institucional", :associacao="institucional" v-if="conteudo")
</template>

<script>
import SejaAssociado from '@sections/associado/sejaAssociado/SejaAssociado.vue'
import Beneficios from '@sections/associado/beneficios/Beneficios.vue'
import Categorias from '@sections/associado/categorias/Categorias.vue'
import Institucional from '@sections/associado/institucional/Institucional.vue'
export default {
	components: {
		SejaAssociado, 
		Beneficios, 
		Categorias, 
		Institucional
	},
	data() {
		return {
			conteudo: null,
		}
	},
	created() {
		this.loadAssociados();
	},
	methods: {
		loadAssociados() {
			this.$axios
				.get(`api/seja-associado`)
				.then(response => this.conteudo = response.data)
		},
		makeScroll(target) {
			document.querySelector(target).scrollIntoView({
				behavior: 'smooth'
			});    
		},
	},
	computed: {
		institucional() {
			if(this.conteudo)
				return this.conteudo.associacoes.find(associacao => associacao.slug == 'institucional')
			else
				return {}
		}
	},
}
</script>

<style lang="stylus" scoped src="./Associado.styl">
</style>